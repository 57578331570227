<template>
  <v-container>

   

    <div  style="margin-top:20px;">
            <v-card class="" elevation="" max-width="" >
                <v-card-title primary-title>
                    Jenkins ( Integração Continua )
                </v-card-title>

                <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">

                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:10px;flex-wrap: wrap;">
                            <div style="margin-right:0px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:330px;display:flex;flex-direction:column;">
                                <h4>Ficha / Web Checkin / QR Code Cadastro / </h4>
                                <div ><b>url</b>:http://fd.economysoftware.com.br:8080</div>
                                <div ><b>Usuario</b>: root</div>
                                <div ><b>Pass</b>: economy.321</div>
                            </div>
            
                        </div>
                    </div>
                    
                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:10px;flex-wrap: wrap;">
                            <div style="margin-right:0px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:330px;display:flex;flex-direction:column;">
                                <h4>Cardapio</h4>
                                <div ><b>url</b>:http://cd.economysoftware.com.br:8080</div>
                                <div ><b>Usuario</b>: root</div>
                                <div ><b>Pass</b>: economy.321</div>
                            </div>
            
                        </div>
                    </div>

                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:10px;flex-wrap: wrap;">
                            <div style="margin-right:0px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:330px;display:flex;flex-direction:column;">
                                <h4>FD Guest</h4>
                                <div ><b>url</b>:fdguest.economysoftware.com.br:8080</div>
                                <div ><b>Usuario</b>: root</div>
                                <div ><b>Pass</b>: economy.321</div>
                            </div>
            
                        </div>
                    </div>

                    

                </div>
            </v-card>
    </div>



  </v-container>
</template>

<script>
// import DadosSistemas from '@/components/Cliente/DadosSistemas'
export default {
//   components: {DadosSistemas},
  data(){
    return{}
  }
}
</script>

<style>

</style>